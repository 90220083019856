import React from 'react'
import { Img } from 'components'
import { useTranslation } from 'react-i18next'

interface Project {
  name: string
  logo: string
  description: string
  background?: string
  website?: string
}

export const OurProjects: React.FC = () => {
  const { t } = useTranslation(['translations', 'clients'])

  const projects: Project[] = [
    {
      name: t('adam.name', { ns: 'clients' }),
      logo: 'https://aiadam.io/static/media/adam-logo.e9f4abc06a479f039b07.png',
      description: t('adam.description', { ns: 'clients' }),
      website: 'https://aiadam.io/b2b',
    },
    {
      name: t('intereuropol.name', { ns: 'clients' }),
      logo: 'https://www.intereuropol.pl/wp-content/uploads/2020/01/inter-logo-1.png',
      description: t('intereuropol.description', { ns: 'clients' }),
      website: 'https://www.intereuropol.pl/',
    },
    // {
    //   name: t('newtone.name', { ns: 'clients' }),
    //   logo: 'https://newtone.nl/wp-content/uploads/2024/03/logo.svg',
    //   description: t('newtone.description', { ns: 'clients' }),
    //   background: '#093626',
    //   website: 'https://newtone.nl/',
    // },
    {
      name: t('western.name', { ns: 'clients' }),
      logo: 'https://www.uwo.ca/web_standards/2023-hp/assets/img/assets/logos/footer-logo.svg',
      description: t('western.description', { ns: 'clients' }),
      background: '#4e2683',
      website: 'https://www.uwo.ca/',
    },
    {
      name: t('ivey.name', { ns: 'clients' }),
      logo: 'https://www.ivey.uwo.ca/images/template/ivey-main-logo.svg',
      description: t('ivey.description', { ns: 'clients' }),
      website: 'https://www.ivey.uwo.ca/',
    },
    {
      name: t('ironvest.name', { ns: 'clients' }),
      logo: 'ironvest-logo.webp',
      description: t('ironvest.description', { ns: 'clients' }),
      website: 'https://www.ironvest.com/',
    },
    {
      name: t('archicom.name', { ns: 'clients' }),
      logo: 'archicom-logo.png',
      description: t('archicom.description', { ns: 'clients' }),
      background: '#0726a8',
      website: 'https://archicom.pl/',
    },
    {
      name: t('sasol.name', { ns: 'clients' }),
      logo: 'sasol-logo.png',
      description: t('sasol.description', { ns: 'clients' }),
      website: 'https://www.sasol.com/',
    },
    {
      name: t('ralls.name', { ns: 'clients' }),
      logo: 'https://ralls.store/img/logo-1685626074.jpg',
      description: t('ralls.description', { ns: 'clients' }),
      background: '#004224',
      website: 'https://ralls.store/',
    },
    {
      name: t('wpc.name', { ns: 'clients' }),
      logo: 'wpc-logo.png',
      description: t('wpc.description', { ns: 'clients' }),
      background: '#000',
    },
    {
      name: t('matait.name', { ns: 'clients' }),
      logo: 'matait-logo.png',
      description: t('matait.description', { ns: 'clients' }),
    },
  ]

  return (
    <>
      <section className='projects'>
        <div className='projects__background'>
          <Img src='projects-bg.png' width='100%' alt='Projects background' />
        </div>

        <div className='projects__container'>
          <h1 className='projects__title'>{t('ourProjects.title')}</h1>
          <p className='projects__description'>{t('ourProjects.description')}</p>

          <div className='projects__grid'>
            {projects.map((project) => (
              <div className='projects__card' key={project.name}>
                <a
                  href={project.website}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='projects__card-link'
                >
                  <div className='projects__card-logo'>
                    <div
                      className='projects__card-logo-wrapper'
                      style={{ backgroundColor: project.background }}
                    >
                      <Img src={project.logo} alt={project.name} width={150} />
                    </div>
                  </div>
                  <div className='projects__card-content'>
                    <h3 className='projects__card-title'>{project.name}</h3>
                    <p className='projects__card-description'>{project.description}</p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
